import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {getAndSetLang, isLangEng, isLangIt} from "../utils/language"


const IndexPage = () => {
  let lang = getAndSetLang("it")

  let content
  let title

  if( isLangIt() ){
      title="Benvenuto"
      content =
      <>
        <p>Benvenuto! Questo è il sito della laurea di Salvo!</p>
        <p>Sei ufficialmente invitato alla mia laurea!</p>
        <p>Clicca su "Discussione" (nel menu) per sapere quando discuterò.</p>
        <p>Clicca su "Festicciola" (sempre nel menu) per sapere quando sarà la festa.</p>
        <p>E goditi le foto di questi due bei cani! (Belli... Più o meno ahahahah)</p>
      </>
  }
  if( isLangEng() ){
    title="Welcome"
    content = 
      <>
        <p>Welcome! This is Salvo's graduation website!</p>
        <p>You are officially invited!</p>
        <p>Click on "Thesis defense" (in the menu) to know when I will defend my thesis</p>
        <p>Click on "Party" (in the menu, again) to know when I will party with you.</p>
        <p>Finally, enjoy the photo of these wonderful dogs! (Not so wonderful afterall ahahah)</p>
      </>
  }

  return (
    <Layout pageTitle={title} lang={lang}>
      {content}
      <StaticImage alt="chiuaua" src="https://ihearthwild.com/wp-content/uploads/2018/10/56708555-51C3-4969-BB55-76541F99FAD3-1024x543.jpeg" />
    </Layout>
  )
}

export default IndexPage

export const Head = () =>  <Seo title={"Home"} />
